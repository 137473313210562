/*=========================================================================================
  File Name: moduleServicesMutations.js
  Description: Services Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.services.unshift(item)
  },
  SET_Services(state, services) {
    state.services = services
  },
  UPDATE_Services(state, Services) {
    const ServicesIndex = state.services.findIndex((p) => p.ID == Services.ID)
    Object.assign(state.services[ServicesIndex], Services)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.services.findIndex((p) => p.ID == itemId)
    state.services.splice(ItemIndex, 1)
},
}
